<template>
  <div>
    <Modal v-model="editModal" width="800">
      <p slot="header" class="text-center">{{ optionType === 1 ? '新建' : '编辑' }}<span>带班人员</span></p>
      <Form ref="formValidate" :model="formModel" :rules="ruleValidate" label-position="top">
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="姓名" prop="userId">
              <!-- <Input v-model="formModel.name" size="small" placeholder="输入姓名" /> -->
              <Select size="small" v-model="formModel.userId" :disabled="optionType === 2" filterable
                placeholder="选择用户(可搜索用户名)" @on-change="onChangeUser">
                <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="联系电话" prop="phone">
              <Input v-model="formModel.phone" type="tel" size="small" placeholder="输入联系电话" />
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8">
          <i-col span="12">
            <FormItem label="工作时段（单选）">
              <RadioGroup v-model="formModel.workTimeType" size="small">
                <Radio v-for="item in timeTypeArray" :key="'timeType_' + item.value" :label="item.value" border>{{
                  item.name }}</Radio>
              </RadioGroup>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="优先级（单选）">
              <RadioGroup v-model="formModel.priorityLevel" size="small">
                <Radio v-for="item in priorityLevelArray" :key="'level_' + item.value" :label="item.value" border>{{
                  item.name }}</Radio>
              </RadioGroup>
            </FormItem>
          </i-col>
        </Row>
        <FormItem label="类型（单选）">
          <RadioGroup v-model="formModel.staffType" size="small">
            <Radio v-for="item in staffTypeArray" :key="'staffType_' + item.value" :label="item.value" border>{{
              item.name
              }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="详细地址">
          <Row :gutter="8">
            <i-col span="8">
              <FormItem prop="provinceCode">
                <Select size="small" v-model="formModel.provinceCode" clearable placeholder="省"
                  @on-change="onChangeProvice">
                  <Option v-for="item in provinceList" :value="item.code" :key="item.code">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem prop="cityCode">
                <Select size="small" v-model="formModel.cityCode" clearable placeholder="市" @on-change="onChangeCity">
                  <Option v-for="item in cityList" :value="item.code" :key="item.code">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem prop="countyCode">
                <Select size="small" v-model="formModel.countyCode" clearable placeholder="区">
                  <Option v-for="item in regionList" :value="item.code" :key="item.code">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </i-col>
          </Row>
          <div class="text-orange">提示：请在地图上搜索具体位置，并选择符合条件的搜索结果。</div>
        </FormItem>
        <a-map ref="aMap" :height="360" :isSearch="true" :funGetPoi="getPoi"></a-map>
        <FormItem prop="address">
          <Input type="text" size="small" v-model="formModel.address" placeholder="输入详细地址" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="editModal = false">取消</Button>
        <Button type="primary" :disabled="btnSubmit" :loading="submitLoading" @click="onConfirm">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { indexMixins } from '../mixins'
import { getUserPage } from '@/api/os/user'
import { getProvinceList, getCityListByProvinceCode, getRegionListByCityCode } from '@/api/sys/provincecityregion'
import { createOrUpdateUser } from '@/api/msp/workdayUser'

import AMap from '@/components/map/AMap.vue'

const getFomrData = () => {
  return {
    address: '',
    city: '',
    cityCode: null,
    county: '',
    countyCode: null,
    name: '',
    phone: '',
    priorityLevel: 1,
    province: '',
    provinceCode: null,
    staffType: 1,
    workTimeType: 1,
    userId: undefined,
    gdLat: '', // 经度
    gdLng: '' // 纬度
  }
}
export default {
  props: {
    optionType: { // 操作类型：1-新增，2-编辑
      type: Number,
      default: 1
    }
  },
  mixins: [indexMixins],
  components: {
    AMap
  },
  data () {
    return {
      editModal: false,
      userQuery: {
        companyId: this.$store.getters.token.userInfo.companyId,
        pageNumber: 1,
        pageSize: 1000
      },
      userList: [],
      formModel: getFomrData(),
      ruleValidate: {
        userId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        phone: [
          { required: true, message: ' ', trigger: 'blur' }, // （支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
          { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: ' ' }
        ],
        provinceCode: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        cityCode: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        countyCode: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        address: [
          { required: true, message: ' ' }
        ]
      },
      provinceList: [],
      cityList: [],
      regionList: [],
      submitLoading: false
    }
  },
  computed: {
    btnSubmit () {
      return this.formModel.gdLat === '' && this.formModel.gdLng === ''
    }
  },
  methods: {
    showModal (user = null) {
      Object.assign(this.formModel, getFomrData())
      this.getUserList()
      if (this.optionType === 2 && Number(user.id) > 0) {
        // 编辑
        this.getUserDetail(user.id)
      } else {
        // 在地图上搜索地址
        this.$nextTick(() => {
          this.$refs.aMap.initAMap()
        })
      }
      this.getProvinceList()
      this.editModal = true
    },
    /**
     * 获取公司下员工
     */
    async getUserList () {
      const result = await getUserPage(this.userQuery)
      if (result && !result.errcode) {
        this.userList = result.list
      }
    },
    onChangeUser () {
      if (this.formModel.userId) {
        const user = this.userList.find(x => x.id === this.formModel.userId)
        this.formModel.name = user.name
        this.formModel.phone = user.mobile
      }
    },
    async getUserDetail (id) {
      const res = await this.getUserInfo(id)

      if (res && !res.errcode) {
        Object.keys(this.formModel).forEach(key => {
          this.formModel[key] = res[key]
        })
        this.formModel.address = res.addressInfo
        // this.formModel.userId = userId
        console.log(this.formModel)
        this.onChangeProvice()
        this.onChangeCity()
        // 在地图上搜索地址
        this.$nextTick(() => {
          this.$refs.aMap.onSearchAddress(this.formModel.city, this.formModel.address)
        })
      }
    },
    getProvinceList () {
      getProvinceList().then((res) => {
        this.provinceList = res
      })
    },
    getCityList () {
      const data = {
        provinceCode: this.formModel.provinceCode
      }

      getCityListByProvinceCode(data).then((res) => {
        this.cityList = res
      })
    },
    getRegionList () {
      const data = {
        cityCode: this.formModel.cityCode
      }
      getRegionListByCityCode(data).then((res) => {
        this.regionList = res
      })
    },
    onChangeProvice () {
      if (!this.formModel.provinceCode) {
        this.formModel.cityCode = null
        this.formModel.countyCode = null
        this.cityList = []
        this.regionList = []
        return false
      }
      this.getCityList()
    },
    onChangeCity () {
      if (!this.formModel.cityCode) {
        this.formModel.countyCode = null
        this.regionList = []
        return false
      }
      this.getRegionList()

      // 高德地图城市切换
      const cityName = this.optionType === 1 ? this.cityList.find(x => x.code === this.formModel.cityCode).name : this.formModel.city
      this.$nextTick(() => {
        this.$refs.aMap.onChangeCityMap(cityName)
      })
    },
    /**
     * 从地图组件获取选中地址数据
     */
    getPoi (data) {
      this.formModel.address = data.name
      this.formModel.gdLat = data.location.lat
      this.formModel.gdLng = data.location.lng
    },
    onConfirm () {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.formModel.province = this.provinceList.find(x => x.code === this.formModel.provinceCode).name
          this.formModel.city = this.cityList.find(x => x.code === this.formModel.cityCode).name
          this.formModel.county = this.regionList.find(x => x.code === this.formModel.countyCode).name

          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误，并提交？',
            onOk: async () => {
              this.submitLoading = true
              const result = await createOrUpdateUser(this.formModel).finally(() => { this.submitLoading = false })
              if (result && result.success) {
                this.$Notice.success({ desc: '操作成功' })
                this.editModal = false
                this.$emit('refreshTable')
              }
            }
          })
        }
      })
    }
  }
}
</script>
